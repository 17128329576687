.Tag {
  border: 1px solid #6d6d6d;
  padding: 0.3rem;
  margin: 0 0.2rem 0.2rem 0;
  color: #6d6d6d;
}

.Tag:hover {
  border-color: #3273dc;
  color: #3273dc;
}
