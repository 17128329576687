.Footer.is-dark {
  background-color: #0a0a0a;
}

.Footer.is-dark h1,
.Footer.is-dark h2,
.Footer.is-dark h3,
.Footer.is-dark h4,
.Footer.is-dark h5,
.Footer.is-dark h6,
.Footer.is-dark p,
.Footer.is-dark span,
.Footer.is-dark div,
.Footer.is-dark section,
.Footer.is-dark i {
  color: #fff;
}

.Footer.is-dark a {
  color: #63a4ff;
}

.Footer.is-dark a:hover {
  color: #5088d6;
}
