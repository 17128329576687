.ProjectCodeButton .Icon {
  margin: 0 0.2rem 0 0;
  font-size: 1.2rem;
}

.ProjectCard {
  display: flex;
  flex-direction: column;
}

.ProjectCard__wrapper {
  display: flex;
}
