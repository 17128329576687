.Hero .subtitle {
  margin-bottom: 0.2rem;
}

@media (max-width: 768px) {
  .Hero {
    height: 50vh;
  }

  .hero-body {
    display: flex;
    align-items: center;
  }
}
