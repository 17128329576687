.Icon {
  font-size: 1.8rem;
  margin: 0 1rem;
}

.Footer.is-dark {
  background-color: #0a0a0a;
}

.Footer.is-dark h1,
.Footer.is-dark h2,
.Footer.is-dark h3,
.Footer.is-dark h4,
.Footer.is-dark h5,
.Footer.is-dark h6,
.Footer.is-dark p,
.Footer.is-dark span,
.Footer.is-dark div,
.Footer.is-dark section,
.Footer.is-dark i {
  color: #fff;
}

.Footer.is-dark a {
  color: #63a4ff;
}

.Footer.is-dark a:hover {
  color: #5088d6;
}

.Hero .subtitle {
  margin-bottom: 0.2rem;
}

@media (max-width: 768px) {
  .Hero {
    height: 50vh;
  }

  .hero-body {
    display: flex;
    align-items: center;
  }
}

.Hero .title {
  font-weight: 300;
  font-size: 2.7rem;
}

.Hero .subtitle {
  margin-bottom: 0.8rem;
  font-size: 1.5rem;
}

/* expand card content when stretched */
.Card__content {
  flex: 1 1;
}

.Tag {
  border: 1px solid #6d6d6d;
  padding: 0.3rem;
  margin: 0 0.2rem 0.2rem 0;
  color: #6d6d6d;
}

.Tag:hover {
  border-color: #3273dc;
  color: #3273dc;
}

.Tags {
  display: flex;
  flex-wrap: wrap;
}

.ProjectCodeButton .Icon {
  margin: 0 0.2rem 0 0;
  font-size: 1.2rem;
}

.ProjectCard {
  display: flex;
  flex-direction: column;
}

.ProjectCard__wrapper {
  display: flex;
}

html {
  background-color: #0a0a0a;
}

body {
  background-color: white;
}

